import AuthLayout from "~/components/AuthLayout";
import { useSearchParams } from "react-router-dom";
import { LoaderFunctionArgs, redirect } from "@remix-run/node";
import checkAuth from "~/checkAuth";

export const loader = async ({ request }: LoaderFunctionArgs) => {
  try {
    await checkAuth(request);
    // The user IS authenticated, redirect to the home page
    return redirect("/");
  } catch (e) {
    // The user is not authenticated, render the login page
    return null;
  }
};

export default function LoginRoute() {
  const [sq] = useSearchParams();

  return (
    <AuthLayout
      title="Log in to your BuildJet account"
      description="Log in to your BuildJet account to manage your installations."
      action="Log in"
      cta="Sign Up"
      ctaHref={`/signup?${sq.toString()}`}
    />
  );
}
